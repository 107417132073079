var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-game",
    class: _vm.isMobile && 'layout-game-wap'
  }, [_c('div', {
    staticClass: "layout-game-content"
  }, [_c('div', {
    staticClass: "layout-game-content-swiper"
  }, [_c('SwiperGameHeader')], 1), _c('div', {
    staticClass: "title-line"
  }, [_c('div', {
    staticClass: "title-line-left"
  }, [_vm._v(" " + _vm._s(_vm.$lang('签到')) + " ")]), _c('div', {
    staticClass: "title-line-right",
    on: {
      "click": _vm.showSignRules
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('签到规则')) + " ")])]), _c('div', [_c('div', {
    staticClass: "title-bg"
  }, [_c('img', {
    attrs: {
      "src": require('@images/game-title-bg.png')
    }
  })]), _c('div', {
    staticClass: "title-bg-2"
  }), _c('div', {
    staticClass: "sign-content"
  }, _vm._l(_vm.signDays, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "sign-item"
    }, [_c('div', {
      staticClass: "sign-item-default"
    }, [_c('div', {
      staticClass: "sign-item-default-days"
    }, [_vm._v(_vm._s(item.day))]), _c('div', {
      staticClass: "sign-item-default-reward"
    }, [_vm._v(_vm._s(item.rect_amount) + " RECT")]), item.showSign ? _c('div', {
      staticClass: "sign-item-sign"
    }, [_c('div', {
      staticClass: "sign-button",
      on: {
        "click": _vm.startSign
      }
    }, [_vm._v(_vm._s(_vm.$lang('签到')))])]) : _vm._e()]), item.signed ? _c('div', {
      staticClass: "sign-item-signed"
    }, [_c('svg', {
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "49",
        "height": "48",
        "viewBox": "0 0 49 48",
        "fill": "none"
      }
    }, [_c('path', {
      attrs: {
        "d": "M19.4553 30.1377L35.2033 14.9914C35.4526 14.7434 35.7664 14.5639 36.1122 14.4714C36.458 14.3789 36.8233 14.3767 37.1703 14.4652C37.5189 14.5523 37.837 14.7271 38.0917 14.9715C38.3465 15.2158 38.5289 15.5209 38.6199 15.8553C38.711 16.1898 38.7076 16.5415 38.6099 16.8742C38.5123 17.2069 38.324 17.5087 38.0644 17.7484L20.8858 34.2332C20.506 34.5964 19.9915 34.8004 19.4553 34.8004C18.919 34.8004 18.4046 34.5964 18.0247 34.2332L9.42946 25.985C9.20913 25.8121 9.02923 25.5967 8.90146 25.3526C8.7737 25.1085 8.70094 24.8414 8.68791 24.5686C8.67489 24.2957 8.7219 24.0233 8.82588 23.7691C8.92986 23.5148 9.08849 23.2845 9.29142 23.093C9.49435 22.9015 9.73704 22.7531 10.0037 22.6576C10.2703 22.5621 10.5549 22.5216 10.839 22.5386C11.1231 22.5557 11.4002 22.63 11.6523 22.7566C11.9045 22.8833 12.126 23.0595 12.3025 23.2738L19.4553 30.1377Z",
        "fill": "#ADFF98"
      }
    })])]) : _vm._e()]);
  }), 0), _c('div', {
    staticClass: "title-bg-4"
  }), _c('div', {
    staticClass: "sign-bottom-tips"
  }, [_vm._v(" " + _vm._s(_vm.$lang('特别提醒：签到奖励请前往个人中心——账户记录查看')) + " ")]), _c('pop-prompt-two', {
    attrs: {
      "show": _vm.popTipShow,
      "title": _vm.$lang('签到规则'),
      "descs": [_vm.$lang('1. 签到周期不设固定天数限制。用户每完成30次签到，系统将自动重置签到周期。'), _vm.$lang('2. 若用户签到中断，之前的签到记录将被保留，用户可在上线后继续从中断前的签到数开始。'), _vm.$lang('3. 每次签到所获得的奖励将直接计入用户的个人中心账户余额，并生成相应的“签到奖励”记录。')]
    },
    on: {
      "update:show": function ($event) {
        _vm.popTipShow = $event;
      }
    }
  }), _c('div', {
    staticClass: "mini-game-line"
  }, [_c('div', {
    staticClass: "mini-game-title"
  }, [_vm._v(_vm._s(_vm.$lang('迷你游戏')))]), _c('div', {
    staticClass: "mini-game-title-btn",
    on: {
      "click": function ($event) {
        _vm.showRecordDialog = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('记录')))])]), _c('div', {
    staticClass: "mini-game-content"
  }, _vm._l(_vm.gameList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mini-game-content-item"
    }, [_c('div', {
      attrs: {
        "href": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onTab(item, index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": item.img
      }
    })])]);
  }), 0)], 1)]), _c('GameMiniRecord', {
    attrs: {
      "dialog-visible": _vm.showRecordDialog
    },
    on: {
      "cancel": function ($event) {
        _vm.showRecordDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };