var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "swiper",
    staticClass: "swiper",
    class: _vm.isMobile && 'swiper-wap'
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_c('div', {
    staticClass: "swiper-slide",
    on: {
      "click": function ($event) {
        return _vm.goSwiper(1);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/swiper-1.jpg')
    }
  })]), _c('div', {
    staticClass: "swiper-slide",
    on: {
      "click": function ($event) {
        return _vm.goSwiper(2);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/swiper-2.jpg')
    }
  })]), _c('div', {
    staticClass: "swiper-slide",
    on: {
      "click": function ($event) {
        return _vm.goSwiper(3);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/swiper-3.jpg')
    }
  })]), _c('div', {
    staticClass: "swiper-slide",
    on: {
      "click": function ($event) {
        return _vm.goSwiper(4);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/swiper-4.jpg')
    }
  })])]), _c('div', {
    staticClass: "swiper-pagination"
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };