import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { createNamespacedHelpers } from "vuex";
import BigNumber from "bignumber.js";
import loadConfig from "@Web3WalletConfig/config.json";
import cookie from "js-cookie";
import ComputeView from "@components/ComputeView.vue";
import LayoutCenter from "@components/LayoutCenter.vue";
import AutoBursh from "@components/AutoBursh.vue";
import vailcode from "@utils/errcode";
import web3Wallet from "@web3Wallet/index";
import ReferralDetailView from "@components/ReferralDetailView.vue";
import NodeRecord from "@components/NodeRecord.vue";
import InviteBindingDialog from "@components/InviteBindingDialog.vue";
import TotalOverview from "@components/TotalOverview.vue";
import LayoutCenterRewards from "@components/LayoutCenterRewards.vue";
import { showCommonTipsDialog } from "@components/CommonDialog";
import SwiperGameHeader from "@components/SwiperGameHeader.vue";
import SwiperHomeHeader from "@components/SwiperHomeHeader.vue";
const walletVuex = createNamespacedHelpers("wallet");
const purchaseVuex = createNamespacedHelpers("purchase");
const serverVuex = createNamespacedHelpers("server");
const nodeVuex = createNamespacedHelpers("node");
export default {
  name: "purchase",
  components: {
    SwiperHomeHeader,
    SwiperGameHeader,
    LayoutCenterRewards,
    TotalOverview,
    InviteBindingDialog,
    NodeRecord,
    ReferralDetailView,
    AutoBursh,
    LayoutCenter,
    ComputeView
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "isMobile", "connect", "address", "userInfo", "balance", 'confirmInviteBinding']),
    ...walletVuex.mapGetters(['balanceLabel']),
    ...purchaseVuex.mapState(["nftSellInfo", "isApprove", "isLoading"]),
    ...serverVuex.mapState(["statData", "firstCondition", "firstListData", "secondCondition", "secondListData"]),
    getHost() {
      return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/';
    },
    userTotalInviter() {
      if (this.userInfo != null) {
        return Number(this.userInfo.user?.total_inviter);
      }
      return 0;
    },
    userTotalCommission() {
      if (this.statData != null) {
        return Number(this.statData.firstInfo?.total_commission + this.statData.secondInfo?.total_commission);
      }
      return 0;
    },
    userBurnAmount() {
      if (this.userInfo && this.userInfo.user && this.userInfo.user.total_coin_stake_info.length > 0) {
        const result = this.userInfo.user.total_coin_stake_info.find(item => item.stake_type == 2);
        if (result) return new BigNumber(result.total_amount).div(10 ** loadConfig.rect_token_decimals).toFixed(2);
      }
      return 0;
    },
    userTotalAmount() {
      if (this.userInfo != null) {
        return BigNumber(this.userInfo.user?.amount).div(10 ** loadConfig.rect_token_decimals).decimalPlaces(2, BigNumber.ROUND_DOWN);
      }
      return 0;
    }
  },
  data() {
    return {
      inviter: "",
      progress: 0,
      buyNum: 1,
      price: 0,
      priceLabel: "0",
      token_enough: true,
      time: {},
      isMax: false,
      selectTab: 0,
      popTipShow: false,
      popInviterTipShow: false,
      popTipContent: '',
      claimLoading: false,
      claimReTryNum: 0,
      popOrderShow: false,
      homeActivateIndex: 0
    };
  },
  watch: {
    connect: {
      async handler(val) {
        this.doGetNftBaseInfo();
        this.getStatData();
        if (this.selectTab == 1) {}
        if (this.selectTab == 2) {
          await this.getFirstListData();
          await this.getSecondListData();
        }
      },
      immediate: true
    },
    isLoading: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    },
    address: {
      async handler(newVal, oldVal) {
        await this.doGetNftBaseInfo();
        await this.doCalcPrice();
        if (this.selectTab == 1) this.getStatData();
        if (this.selectTab == 2) {
          await this.getFirstListData();
          await this.getSecondListData();
        }
        await this.refreshUserInfo();
      },
      immediate: true,
      deep: true
    },
    nftSellInfo: {
      async handler(newVal, oldVal) {
        this.progress = newVal.progress || 0;
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    setTimeout(() => {
      this.inviter = cookie.get("t") || localStorage.getItem('t') || '';
    }, 300);
    const walletConnected = localStorage.getItem("WALLET_CONNECTED");
    if (walletConnected == "true" || walletConnected == true) {
      await this.doConnect(true);
    }
    await this.doGetNftBaseInfo();
    await this.doCalcPrice();
    if (this.selectTab == 1) await this.getStatData();
    if (this.selectTab == 2) {
      await this.getFirstListData();
      await this.getSecondListData();
    }
  },
  methods: {
    ...walletVuex.mapActions(["doConnect", "refreshUserInfo", "changeInviteBindingDialog"]),
    ...purchaseVuex.mapActions(["getNftBaseInfo", "calcPrice", "checkApprove", "doApprove", "doBuy"]),
    ...serverVuex.mapMutations(["setFirstCondition", "setSecondCondition"]),
    ...serverVuex.mapActions(["getStatData", "getFirstListData", "getSecondListData"]),
    ...nodeVuex.mapActions(["changeRecordDialog"]),
    async onSelectTab(t) {
      this.selectTab = t;
      if (this.selectTab == 1) {
        await this.getStatData();
      }
      if (this.selectTab == 2) {
        await this.getFirstListData();
        await this.getSecondListData();
      }
    },
    async doGetNftBaseInfo() {
      clearTimeout(this.time[100]);
      await this.getNftBaseInfo();
      this.time[100] = setTimeout(async () => {
        await this.doGetNftBaseInfo();
      }, 20000);
    },
    buyNumKeyUp() {
      this.buyNum = parseInt(this.buyNum);
      clearTimeout(this.time[4]);
      this.time[4] = setTimeout(() => {
        this.doCalcPrice();
      }, 200);
    },
    async doCalcPrice(s = 0) {
      if (Number(this.buyNum) > Number(this.nftSellInfo.mintLimit) - Number(this.nftSellInfo.userMint)) {
        this.buyNum = Number(this.nftSellInfo.mintLimit) - Number(this.nftSellInfo.userMint);
        if (s) {
          this.isMax = true;
        }
      }
      if (Number(this.buyNum) > Number(this.nftSellInfo.openTotalMint) - Number(this.nftSellInfo.mintTotal)) {
        this.buyNum = Number(this.nftSellInfo.openTotalMint) - Number(this.nftSellInfo.mintTotal);
        if (s) {
          this.isMax = true;
        }
      }
      if (this.buyNum > 0) {
        await this.calcPrice([this.buyNum, async res => {
          this.price = res[0];
          if (new BigNumber(this.price).gt(this.nftSellInfo.usdtBalance)) {
            this.token_enough = false;
          } else {
            this.token_enough = true;
          }
          this.priceLabel = new BigNumber(this.price).div(10 ** loadConfig.usdt_decimals).toFixed(0);
          await this.checkApprove(new BigNumber(this.price).toFixed(0));
        }]);
      } else {
        this.price = 0;
        this.priceLabel = "0";
      }
    },
    onMin() {
      this.buyNum = 1;
      this.isMax = false;
      clearTimeout(this.time[3]);
      this.time[3] = setTimeout(() => {
        this.doCalcPrice();
      }, 200);
    },
    onMax() {
      this.buyNum = Number(this.nftSellInfo.mintLimit) - Number(this.nftSellInfo.userMint);
      this.isMax = true;
      clearTimeout(this.time[0]);
      this.time[0] = setTimeout(() => {
        this.doCalcPrice(1);
      }, 200);
    },
    onSub() {
      this.buyNum = this.buyNum - 1 > 0 ? this.buyNum - 1 : 1;
      this.isMax = false;
      clearTimeout(this.time[1]);
      this.time[1] = setTimeout(() => {
        this.doCalcPrice();
      }, 200);
    },
    onAdd() {
      if (this.buyNum + 1 <= Number(this.nftSellInfo.mintLimit) - Number(this.nftSellInfo.userMint)) {
        this.buyNum = this.buyNum + 1;
      } else {
        this.buyNum = Number(this.nftSellInfo.mintLimit) - Number(this.nftSellInfo.userMint);
        this.isMax = true;
      }
      clearTimeout(this.time[2]);
      this.time[2] = setTimeout(() => {
        this.doCalcPrice(1);
      }, 200);
    },
    async onApprove() {
      if (this.checkUserInviter()) {
        return;
      }
      if (this.isLoading) return;
      await this.doApprove([this.price, () => {}]);
    },
    // async onConfrim() {
    //   if (this.inviter) {
    //     await this.onBuy()
    //   } else {
    //     this.popTipShow = true;
    //   }
    // },
    // async doConfirm() {
    //   this.popTipShow = false;
    //   await this.onBuy()
    // },
    checkUserInviter() {
      if (!this.confirmInviteBinding && this.userInfo?.user?.must_inviter == 1) {
        this.changeInviteBindingDialog(true);
        return true;
      }
      return false;
    },
    async onBuy(type) {
      if (this.checkUserInviter()) {
        return;
      }
      if (type == 1) return;
      if (this.isLoading) return;
      await this.doBuy([new BigNumber(this.price).toFixed(0), this.buyNum, this.inviter, () => {
        this.$toasted.success('Trade successfully');
      }]);
    },
    onFirstPageChange(e) {
      this.setFirstCondition({
        "page": e
      });
      this.getFirstListData();
    },
    onSecondPageChange(e) {
      this.setSecondCondition({
        "page": e
      });
      this.getSecondListData();
    },
    onFirstPageSizeChange(e) {
      this.setFirstCondition({
        "pageSize": e
      });
    },
    onSecondPageSizeChange(e) {
      this.setSecondCondition({
        "pageSize": e
      });
    },
    async onClaim() {
      try {
        console.log("web3Wallet", web3Wallet);
        if (this.claimLoading) return;
        if (BigNumber(this.balanceLabel).lt("0.001")) {
          this.$toasted.error(this.$lang('Insufficient GAS fee'));
          return;
        }
        let amount = BigNumber(this.userInfo.user?.amount).div(10 ** loadConfig.rect_token_decimals).decimalPlaces(2, BigNumber.ROUND_DOWN);

        // if (amount.indexOf(".") !== -1)
        //   amount = amount.split(".")[0];

        if (!amount || amount == '0') {
          this.$toasted.error(this.$lang('Nothing to claim'));
          return;
        }
        this.claimLoading = true;
        const message = 'claim';
        let signed = await web3Wallet.signMessage(message);
        let params = {
          owner: this.address,
          amount,
          signedMsg: message,
          signed: signed
        };
        let rs = await this.$http.post("stake/claim", params);
        vailcode(rs, async () => {
          try {
            await this._doClaim(rs);
          } catch (eee) {
            this.claimLoading = false;
          }
        });
      } catch (e) {
        console.log(e);
        this.claimLoading = false;
      }
    },
    async _doClaim(rs) {
      try {
        const res = await web3Wallet.contract("stakeClaim").send("claim", [rs.data.orderId, rs.data.claimAmount, rs.data.timestamp, rs.data.seeds]);
        this.$toasted.success(this.$lang('claim submission successful'));
        this.claimLoading = false;
        this.claimReTryNum = 0;
        this.refreshUserInfo();
      } catch (ee) {
        this.$http.post("/appcatch", {
          address: this.address,
          eee: ee
        });
        if ((ee.message || '').indexOf("rejected") === -1 && this.claimReTryNum <= 5) {
          this.claimReTryNum++;
          this._doClaim(rs);
        } else {
          this.claimReTryNum = 0;
          this.popTipContent = this.$lang('Transaction fails, you can open the record list and try again.');
          this.popInviterTipShow = true;
          throw new Error(ee);
        }
      }
    },
    viewOrder() {
      if (!this.address) return;
      this.popOrderShow = true;
    },
    async onConfirmBind() {
      let signedMsg = "binding";
      let signed = await web3Wallet.signMessage(signedMsg);
      let params = {
        owner: this.address,
        inviter: this.inviter,
        signedMsg: signedMsg,
        signed: signed
      };
      let rs = await this.$http.post("stake/bind/inviter", params);
      vailcode(rs, async () => {
        showCommonTipsDialog(this.$lang('success'), this.$lang('Bind Success')).then(() => {}).catch(() => {});
        await this.refreshUserInfo();
      });
    }
  }
};