import { createNamespacedHelpers } from "vuex";
import ProfileDialogRecharge from "@components/ProfileDialogRecharge.vue";
import Vue from "vue";
import { formatRectCoinAmount } from "@utils";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    ProfileDialogRecharge
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  data() {
    return {
      showDialogTransfer: false,
      showDialogRecharge: false,
      showDialogWithdraw: false,
      showDialogProfileRecord: false,
      accountRectBalance: 0
    };
  },
  watch: {
    address: {
      handler(newVal, oldVal) {
        this.getAccountBalance();
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getAccountBalance();
  },
  methods: {
    confirmTransfer() {
      this.showDialogTransfer = false;
      this.getAccountBalance();
    },
    confirmWithdraw() {
      this.showDialogWithdraw = false;
      this.getAccountBalance();
    },
    confirmRecharge() {
      this.showDialogRecharge = false;
      this.getAccountBalance();
    },
    getAccountBalance() {
      this.$loading.show();
      let params = {
        "owner": this.address
      };
      Vue.prototype.$http.post("staker/info", params).then(data => {
        data = data.data;
        this.accountRectBalance = formatRectCoinAmount(data.rect_amount);
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>-------');
        console.log(this.accountRectBalance);
        console.log(data.rect_amount);
      }).catch(() => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};