import { createNamespacedHelpers } from "vuex";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      speed: 600,
      spaceBetween: 300,
      modules: [Autoplay, Pagination],
      autoplay: {
        delay: 2000
      },
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        bulletClass: "swiper-pagination-bullet-2",
        bulletActiveClass: "swiper-pagination-bullet-active-2"
      }
    });
  },
  methods: {
    goSwiper(page) {
      if (page == 1) {
        window.open("https://x.com/ReflectionAI_/status/1883163130072801302/photo/1");
      } else if (page == 2) {
        window.open("https://x.com/ReflectionAI_/status/1883901285684093204");
      } else if (page == 3) {
        window.open("https://x.com/ReflectionAI_/status/1883908714757009476");
      } else if (page == 4) {
        window.open("https://x.com/ReflectionAI_/status/1886407589224915436");
      }
    }
  }
};